
/* local */

// const baseUrl ="http://192.168.2.182:8080"

/* staging */
const baseUrl ="https://copytrading-api.netset-software.com"


export const config = {
    apiUrl: `${baseUrl}/api/v1`,
    // nodeApiUrl: `${nodeApiUrl}/api/v1`,
    imageUrl: baseUrl + '/',
}

export const SOL_ADDRESS = "So11111111111111111111111111111111111111112";