import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { botApis } from "../services/botApis";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling, formatBalance } from "../Common/CommonComponents";
import { useNavigate } from "react-router-dom";
import ManualBuy from "./ManualBuy";
import ManualSell from "./ManualSell";
import Loader from "../Common/Loader";
import { connectionWeb3 } from "../config/solanaWeb3";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch"; // Import Switch component
import Swal from "sweetalert2";
import TransactionLog from "../Common/TransactionLog";
import AddressLog from "./AddressLog";
import CopyTokenAddress from "./CopyTokenAddress";

const Home = () => {
    const navigate = useNavigate();
    const [copied, setCopied] = useState({});
    const tokenLogsRef = useRef();
    const copyTokenAddressRef = useRef()
    const [showModal, setShowModal] = useState(false);
    const [address, setAddress] = useState("");
    const [error, setError] = useState("");
    const { getTokenBalance, accountInfo } = connectionWeb3();
    const [adminData, setAdminData] = useState({});
    const [serverBotStatus, setServerBotStatus] = useState(false);
    const [userLoader, setUserLoader] = useState(false);
    const [latestTokens, setlatestTokens] = useState([])
    const [boughtTokens, setBoughtTokens] = useState([]);
    const [nextPage, setNextPage] = useState(0);
    const [checkLogStatus, setCheckLogStatus] = useState(false)
    const [callTypeStatus, setCallTypeStatus] = useState("false")
    const callTypeStatusRef = useRef(false);
    const adminDataRef = useRef();
    const [addTokenLoader, setAddTokenLoader] = useState(false)

    function logout() {
        Swal.fire({
            title: 'Are you sure you want to log out?',
            text: "You will be logged out of your account.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log me out'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                navigate("/");
            }
        });
    }

    /* hit the required function */
    useEffect(() => {
        getAccountdetail()
        getWalletAddressLogWithStart(nextPage, "TOP")
        getCheckLogStatus();
        checkBalance()
    }, [])

    /* handle Add log */
    const handleAddLog = async () => {
        // Check if address is blank
        if (!address || address?.trim() === "") {
            setError('Address cannot be blank');
            return;
        }
        const tokenAccountInfo = await accountInfo(address)
        if (!tokenAccountInfo) {
            setError("Invalid Address");
            return;
        }
        setAddTokenLoader(true)
        startTransactionLogs(address)
    }

    // checkBalance
    async function checkBalance() {
        if (adminDataRef.current?.wallet_address) {
            const balance = await getTokenBalance(adminDataRef.current.wallet_address);
            setAdminData({ ...adminDataRef.current, balance: balance });
        }
    }

    /* start the transaction log */
    const startTransactionLogs = async (address) => {
        const params = {
            address: address
        };
        const response = await botApis.startTransactionLog(params).then((response) => {
            if (response.status === 200) {
                setShowModal(false)
                setAddTokenLoader(false)
                setAddress("")
                getCheckLogStatus()
            }
        })
            .catch((error) => {
                setAddTokenLoader(false)
                exceptionHandling(error);
                console.log("error", error?.response.data.message)
            })
    }

    /* stop the transaction log */
    const stopTransactionLogs = async (address) => {
        const params = {
            address: address
        };
        const response = await botApis.stopTransactionLog(params).then((response) => {
            if (response.status === 200) {
                setShowModal(false)
                setAddress("")
                getCheckLogStatus()
            }
        })
            .catch((error) => {
                console.log("error", error)
                exceptionHandling(error);
            })
    }

    /* copy wallet address */
    const copyTokenAddress = (token, index) => {
        navigator.clipboard.writeText(token)
            .then(() => {
                setCopied({ ...copied, [index]: true });
                setTimeout(() => setCopied({ ...copied, [index]: false }), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.error('Failed to copy:', error));
    };
    const maskAddress = (address, characters = 5) => {
        const startChars = address?.substring(0, characters); // Get the first 6 characters
        const endChars = address?.substring(address?.length - characters); // Get the last 6 characters
        return `${startChars}.....${endChars}`; // Combine with dots in between
    };

    /* Account detail save */
    async function updateUser(type) {
        setUserLoader(false);
        try {
            let errorTemp = { ...error }, status = false;
            if (!adminData.wallet_address || !adminData.wallet_address.trim()) {
                status = true;
                errorTemp.wallet_address = "Wallet address is required";
            }
            if (!adminData.wallet_private_key || !adminData.wallet_private_key.trim()) {
                status = true;
                errorTemp.wallet_private_key = "Wallet private key is required";
            }

            if (!adminData.max_lamports || (adminData.max_lamports && adminData.max_lamports <= 0)) {
                status = true;
                errorTemp.max_lamports = "Max Lamports is required";
            }

            if (!adminData.slippage || (adminData.slippage && adminData.slippage <= 0)) {
                status = true;
                errorTemp.slippage = "Slippage is required";
            }

            if (!adminData.rpc_url || !adminData.rpc_url.trim()) {
                status = true;
                errorTemp.rpc_url = "RPC URL is required";
            }

            if (status) {
                setError(errorTemp);
                return;
            }

            const params = {
                wallet_address: adminData.wallet_address,
                wallet_private_key: adminData.wallet_private_key,
                max_lamports: (Number(adminData.max_lamports) * 10 ** 9),
                slippage: Number(adminData.slippage),
                rpc_url: adminData?.rpc_url
            }
            const response = await botApis.updateUser(params);
            if (response.status === 200) {
                setAdminData({ ...response?.data?.data?.user, max_lamports: (Number(response.data.data.user.max_lamports) / 10 ** 9) });
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            setUserLoader(false);
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    /* get account detail */
    const getAccountdetail = async () => {
        try {
            const response = await botApis.getAccountDetails();
            if (response.status === 200) {
                const responseData = { ...response?.data?.data?.user, max_lamports: (Number(response?.data?.data?.user?.max_lamports) / 10 ** 9) };
                setAdminData(responseData);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    function convertUtcToLocal(utcTimeString) {
        const utcDate = new Date(utcTimeString);
        const offsetMinutes = utcDate.getTimezoneOffset();
        const localDate = new Date(utcDate.getTime() - (offsetMinutes * 60000));
        // Format the local time
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Include AM/PM indicator
        };
        const formattedLocalTime = localDate.toLocaleString(undefined, options);
        return formattedLocalTime;
    }

    /* get wallet Address Log */
    const getWalletAddressLogWithStart = async (page, callType) => {
        try {
            const response = await botApis.getWalletAddressLog(page);
            if (response.status === 200) {
                let responseData = response.data.data;

                if (callType == "BOTTOM") {
                    let latestTokensTemp = [...latestTokens?.logs];
                    latestTokensTemp.push(...responseData?.logs);
                    responseData.logs = latestTokensTemp;
                    setCallTypeStatus("true")
                    callTypeStatusRef.current = true
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setlatestTokens(responseData)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    /* scroll pagination on wallet address */
    const onScrollTokenLogs = async () => {
        if (tokenLogsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = tokenLogsRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(latestTokens.totalPages);
                if (page < totalPages) {
                    await getWalletAddressLogWithStart(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };


    /* check log status */
    const getCheckLogStatus = async () => {
        try {
            const response = await botApis.checkLogStatus();
            if (response.status === 200) {
                setCheckLogStatus(response.data.data.logStatus)
                if (response.data.data.logStatus == true && callTypeStatusRef.current == false) {
                    getWalletAddressLogWithStart(nextPage, "TOP");
                    return;
                }

            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }


    return (
        <>
            <section className="top-area">
                <Container fluid>
                    <div className="head-top-right">
                        {(adminData?.wallet_address) ?
                            <h6 className="main-wallet-top"> {maskAddress(adminData?.wallet_address)}
                                <img src={require("../assets/images/balance-icon.svg").default} alt="img" />
                                <span>  {adminData?.balance && formatBalance(adminData?.balance)} SOL </span>
                            </h6>
                            :
                            <Skeleton className="main-wallet-top mb-2" height={25} width={200} />
                        }
                        <h6 className="main-wallet-top cursor-pointer" onClick={() => logout()}>
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </h6>
                    </div>

                    <Row className="mb-3">
                        <Col xl={12}>
                            <Row>
                                <Col md={12} lg={6}  xl={4} className="mb-3">
                                    <div className="top-boxes">
                                        <Form>
                                            <div className="selling-top-right mb-0">
                                                <h5>Account Detail</h5>
                                            </div>
                                            <Row>
                                                <Col sm={6} md={12} lg={12} className="padding-right">
                                                    <Form.Group className="mb-1" controlId="formBasicEmail">
                                                        <Form.Label>Account Public key</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Account Public key" value={adminData?.wallet_address} onChange={(e) => setAdminData({ ...adminData, wallet_address: e.target.value })} />
                                                        {(!adminData?.wallet_address || !adminData?.wallet_address.trim()) && error.wallet_address && <p className="error">{error.wallet_address}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} >
                                                    <Form.Group className="mb-1" controlId="formBasicPassword">
                                                        <Form.Label>Account Private key</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Account Private key" value={adminData?.wallet_private_key} onChange={(e) => setAdminData({ ...adminData, wallet_private_key: e.target.value })} />
                                                        {(!adminData?.wallet_private_key || !adminData?.wallet_private_key.trim()) && error.wallet_private_key && <p className="error">{error.wallet_private_key}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} >
                                                    <Row>
                                                        <Col sm={6} md={6} lg={6} >
                                                            <Form.Group controlId="formBasicPassword">
                                                                <Form.Label>Max Lamports/Gas Fee</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter max Lamports" value={adminData?.max_lamports} onChange={(e) => setAdminData({ ...adminData, max_lamports: e.target.value })} />
                                                                {(!adminData?.max_lamports) && error.max_lamports && <p className="error">{error.max_lamports}</p>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={6} md={6} lg={6} >
                                                            <Form.Group controlId="formBasicPassword">
                                                                <Form.Label>slippage (Percentage)</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter slippage" value={adminData?.slippage} onChange={(e) => setAdminData({ ...adminData, slippage: e.target.value })} />
                                                                {(!adminData?.slippage) && error.slippage && <p className="error">{error.slippage}</p>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={6} md={12} lg={12} className="padding-right">
                                                            <Form.Group className="mb-1" controlId="formBasicEmail">
                                                                <Form.Label>RPC URL</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Account RPC URL" value={adminData?.rpc_url} onChange={(e) => setAdminData({ ...adminData, rpc_url: e.target.value })} />
                                                                {(!adminData?.rpc_url || !adminData?.rpc_url.trim()) && error.rpc_url && <p className="error">{error.rpc_url}</p>}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Button className="mb-2" type="button" variant="unset" disabled={userLoader}
                                                onClick={() => updateUser("USER")}
                                            >
                                                {userLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Save"}
                                            </Button>
                                        </Form>
                                    </div>
                                </Col>

                                <Col md={12} lg={6} xl={4} className="mb-3" >
                                    <div className="top-boxes selling-top-box pb-0">

                                        <div className="selling-top-right">
                                            <h5>copy wallet address</h5>
                                            <Button className="add-token-btn" variant="primary" onClick={() => setShowModal(true)}>Add wallet</Button>
                                        </div>

                                        <CopyTokenAddress maskAddress={maskAddress} copyTokenAddress={copyTokenAddress} copied={copied} startTransactionLogs={startTransactionLogs} stopTransactionLogs={stopTransactionLogs} />

                                    </div>
                                </Col>

                                <Col md={12} lg={6} xl={4} className="mb-3">
                                    <AddressLog checkLogStatus={checkLogStatus} maskAddress={maskAddress} copyTokenAddress={copyTokenAddress} copied={copied} />
                                </Col>
                                <Col md={12} lg={6} xl={12} className="mb-3">
                                    <TransactionLog checkLogStatus={checkLogStatus} maskAddress={maskAddress} copyTokenAddress={copyTokenAddress} copied={copied} convertUtcToLocal={convertUtcToLocal} />
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    {/* <Row>
                        <Col md={12} lg={12} xl={12} className="mb-3">
                            <TransactionLog checkLogStatus={checkLogStatus} maskAddress={maskAddress} copyTokenAddress={copyTokenAddress} copied={copied} convertUtcToLocal={convertUtcToLocal} />
                        </Col>
                    </Row> */}
                </Container>
            </section>

            {/* Add trasaction log modal */}

            <Modal show={showModal} onHide={() => { setShowModal(false); setAddress("") }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>copy wallet address </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter copy token address"
                                value={address}
                                onChange={(e) => { setAddress(e.target.value); setError("") }}
                                onKeyDown={(e) => {
                                    if (e.target.value === "" && e.key === " ") {
                                        e.preventDefault();
                                    }
                                }}

                            />
                        </Form.Group>
                        {error && <p style={{ "color": "red" }}>{error}</p>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); setAddress("") }}>Close</Button>
                    <Button variant="primary" disabled={addTokenLoader} onClick={handleAddLog}>
                        {addTokenLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Add"}
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    );
};
export default Home;