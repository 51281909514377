import axios from "axios";
import { config } from "../config/config";
import { getToken, jsonHeaders } from "../helpers/auth-header";

const configJsonHeaders = () => {
    let jsonHeader = jsonHeaders();
    jsonHeader.Authorization = getToken();
    return {
        headers: jsonHeader
    }
}

// const configMultipartHeaders = () => {
//     let formDataHeaders = formDataHeader();
//     formDataHeaders.Authorization = getToken();
//     return {
//         headers: formDataHeaders
//     }
// }
export const botApis = {
    login,
    getTransactionLogData,
    startTransactionLog,
    stopTransactionLog,
    getAccountDetails,
    getWalletAddressLog,
    getTransactionLogs,
    checkLogStatus,


    updateUser,
    lossStatus,
    getLatestTokens,
    getLatestTransactions,
    swapToken,
    getBoughtTokens,
    // -------------Telegram----------
    checkTelegramConnection,
    startTelegramConnection,
    stopTelegramConnection,
    // -------------Pool----------------
    checkPool,
    startPool,
    stopPool,
    startInProcess,
    // -------------Buy----------------
    checkBuy,
    startBuy,
    stopBuy,
    // -------------Buy----------------
    checkSell,
    startSell,
    stopSell,
    // -------------Manual Token----------------
    checkManualPool,
    startManualPool,
    stopManualPool
}

function login(params) {
    return axios.post(`${config.apiUrl}/user/login`,params);
}

function getTransactionLogData(page) {
    return axios.get(`${config.apiUrl}/log/list?page=${page}`,configJsonHeaders());
}

function getAccountDetails(){
    return axios.get(`${config.apiUrl}/user/`,configJsonHeaders());
}
/* get transaction log */

function getTransactionLogs(page){
    return axios.get(`${config.apiUrl}/log/transactions?page=${page}`,configJsonHeaders());  
}

/* get wallet address log */
function getWalletAddressLog(page){
    return axios.get(`${config.apiUrl}/log/addresses?page=${page}`,configJsonHeaders());  
}

function startTransactionLog(params){
    return axios.post(`${config.apiUrl}/log/start`,params, configJsonHeaders());
}

function stopTransactionLog(params){
    return axios.post(`${config.apiUrl}/log/stop`,params, configJsonHeaders());
}

function updateUser(params) {
    return axios.put(`${config.apiUrl}/user/update`,params,configJsonHeaders());
}

/* check log status */
function checkLogStatus(){
    return axios.get(`${config.apiUrl}/log/status`,configJsonHeaders());  
}









function lossStatus(params) {
    return axios.put(`${config.apiUrl}/admin/loss-status`,params,configJsonHeaders());
}

function getLatestTokens(page) {
    return axios.get(`${config.apiUrl}/token?page=${page}`,configJsonHeaders());
}

function getLatestTransactions(page,) {
    return axios.get(`${config.apiUrl}/token/transactions?page=${page}`,configJsonHeaders());
}

function getBoughtTokens(page,) {
    return axios.get(`${config.apiUrl}/token/bought?page=${page}`,configJsonHeaders());
}

function swapToken(params) {
    return axios.post(`${config.nodeApiUrl}/swap`,params,configJsonHeaders());
}

// -------------------------------------------Telegram----------------------------------------------------------
function checkTelegramConnection() {
    return axios.get(`${config.apiUrl}/telegram/check_connection`,configJsonHeaders());
}

function startTelegramConnection() {
    return axios.post(`${config.apiUrl}/telegram/start_connection`,{},configJsonHeaders());
}

function stopTelegramConnection() {
    return axios.delete(`${config.apiUrl}/telegram/stop_connection`,configJsonHeaders());
}
// -------------------------------------------Pool----------------------------------------------------------
function checkPool() {
    return axios.get(`${config.apiUrl}/token/check_pool`,configJsonHeaders());
}

function startPool() {
    return axios.post(`${config.apiUrl}/token/start_pool`,{},configJsonHeaders());
}

function stopPool() {
    return axios.delete(`${config.apiUrl}/token/stop_pool`,configJsonHeaders());
}


function startInProcess() {
    return axios.post(`${config.apiUrl}/token/in_process`,{},configJsonHeaders());
}

// ------------------------------------------------Buy-------------------------------------------------------
function checkBuy() {
    return axios.get(`${config.apiUrl}/token/check_buy`,configJsonHeaders());
}

function startBuy() {
    return axios.post(`${config.apiUrl}/token/start_buy`,{},configJsonHeaders());
}

function stopBuy() {
    return axios.delete(`${config.apiUrl}/token/stop_buy`,configJsonHeaders());
}

// ------------------------------------------------Sell-------------------------------------------------------
function checkSell() {
    return axios.get(`${config.apiUrl}/token/check_sell`,configJsonHeaders());
}

function startSell() {
    return axios.post(`${config.apiUrl}/token/start_sell`,{},configJsonHeaders());
}

function stopSell() {
    return axios.delete(`${config.apiUrl}/token/stop_sell`,configJsonHeaders());
}



// ------------------------------------------------Manual Token Pool-------------------------------------------------------
function checkManualPool() {
    return axios.get(`${config.apiUrl}/token/check_manual_pool`,configJsonHeaders());
}

function startManualPool() {
    return axios.post(`${config.apiUrl}/token/start_manual_pool`,{},configJsonHeaders());
}

function stopManualPool() {
    return axios.delete(`${config.apiUrl}/token/stop_manual_pool`,configJsonHeaders());
}