import { useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";
import Switch from "react-switch";


const CopyTokenAddress = ({ maskAddress, copyTokenAddress, copied, startTransactionLogs, stopTransactionLogs }) => {
    const [copyTokenAddressList, setCopyTokenAddressList] = useState([])
    const [copyStatus, setCopyStatus]=useState(true)
    const copyTokenAddressRef = useRef(null);
    const [nextPage, setNextPage] = useState(0);
    useEffect(() => {
        getCopyTokenAddress(nextPage, "TOP")
    }, [])

    const getCopyTokenAddress = async (page, callType) => {
        try {
            const response = await botApis.getTransactionLogData(page);
            if (response.status === 200) {
                let responseData = response.data.data;

                if (callType == "BOTTOM") {
                    let latestTokensTemp = [...copyTokenAddressList?.logs];
                    latestTokensTemp.push(...responseData?.logs);
                    responseData.logs = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setCopyTokenAddressList(responseData)
                setCopyStatus(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    const onScrollCopyTokenAddress = async () => {
        if (copyTokenAddressRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = copyTokenAddressRef.current;
            // console.log("scrollTop + clientHeight === scrollHeight", scrollTop + clientHeight, scrollHeight);
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(copyTokenAddressList.totalPages);
                if (page < totalPages) {
                    await getCopyTokenAddress(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };

    const tokenLogsObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsCallObserverTop(false);
            isCallObserverTopRef.current = false;
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        tokenLogsObserverTop.current = new IntersectionObserver(handleTokenLogsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (copyTokenAddressList?.logs?.length > 0) {
            tokenLogsObserverTop.current.observe(document.querySelector('.latest-tokens-logs-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, copyTokenAddressList, copyTokenAddressList?.logs]);

    const handleTokenLogsObserverTop = async (entities) => {
        const target = entities[0];
        // console.log("test------->",target.isIntersecting , !isCallObserverTopRef.current );
        if (target.isIntersecting && !isCallObserverTopRef.current ) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getCopyTokenAddress(0, "TOP");
        }
    };

    return (<>
        {/* < className="top-boxes selling-top-box mb-3"> */}
           
                <Row>
                    <div className="transaction-logs transaction-body token-mis-area token-address"
                        ref={copyTokenAddressRef} onScroll={onScrollCopyTokenAddress}
                    >

                        <Table>
                            <thead>
                                <tr>
                                    <th>Token Address</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    copyStatus ==true
                                // (copyTokenAddressList?.logs?.length <= 0)
                                 ? (<tr>
                                    <td className="token-front "><Skeleton height={15} width={250} count={20} /></td>
                                    <td className="token-front score-board-right"><Skeleton height={15} width={50} count={20} /></td>
                                </tr>) :
                                copyTokenAddressList?.logs?.length >0 ?
                                    copyTokenAddressList?.logs?.map((item, index) => {
                                        return (
                                            <>
                                                {index == 1 && <div className="latest-tokens-logs-top"></div>}
                                                <tr key={index}>
                                                                <td>{maskAddress(item?.address)}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestTransactions${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                                    >
                                                                        <i className="far fa-copy" onClick={() => copyTokenAddress(item?.address, `latestTransactions${index}`)}></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    <Switch
                                                                        onChange={(e) => item?.log_status == false ? startTransactionLogs(item?.address) : stopTransactionLogs(item?.address)}
                                                                        checked={item?.log_status}
                                                                    />
                                                                </td>
                                                            </tr>
                                            </>
                                        )
                                    }):
                                    <>
                                    {/* <p>No Wallet Address is Here.</p> */}
                                    <td colSpan={8} className="no-transaction-area">No Wallet Address is Here.</td>
                                    </>
                                    }
                            </tbody>
                        </Table>
                    </div>
                </Row>
            {/* </Form> */}
        {/* </div> */}
    </>)
}
export default CopyTokenAddress;