// import React, { useState } from "react"
// import Skeleton from "react-loading-skeleton";
// import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";

// function TransactionLog(props){
//     const [copied, setCopied] = useState({});
//     const maskAddress = (address, characters = 5) => {
//         const startChars = address?.substring(0, characters); // Get the first 6 characters
//         const endChars = address?.substring(address?.length - characters); // Get the last 6 characters
//         return `${startChars}.....${endChars}`; // Combine with dots in between
//     };

//     const copyTokenAddress = (token, index) => {
//         navigator.clipboard.writeText(token)
//             .then(() => {
//                 setCopied({ ...copied, [index]: true });
//                 setTimeout(() => setCopied({ ...copied, [index]: false }), 2000); // Reset copied state after 2 seconds
//             })
//             .catch((error) => console.error('Failed to copy:', error));
//     };

//     function convertUtcToLocal(utcTimeString) {
//         const utcDate = new Date(utcTimeString);
//         const offsetMinutes = utcDate.getTimezoneOffset();
//         const localDate = new Date(utcDate.getTime() - (offsetMinutes * 60000));
//         // Format the local time
//         const options = {
//             year: 'numeric',
//             month: '2-digit',
//             day: '2-digit',
//             hour: '2-digit',
//             minute: '2-digit',
//             second: '2-digit',
//             hour12: true // Include AM/PM indicator
//         };
//         const formattedLocalTime = localDate.toLocaleString(undefined, options);
//         return formattedLocalTime;
//     }

//     return(
//         <div className="transaction-logs-left mb-3">
//         <h6>Transaction Logs</h6>
//         <div className="transaction-logs snipperbot-transaction transaction-body bottom-table"
//         >
//             <Table >
//                 <thead>
//                     <tr>
//                         <th>Name</th>
//                         <th>Address</th>
//                         <th>signature</th>
//                         <th>Token Amount</th>
//                         <th>Amount </th>
//                         <th>Action Start</th>
//                         <th>Action End</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <div className="latest-transactions-top"></div>
//                     {(props?.data?.transactions?.length <= 0) ? (<tr>
//                         <td ><Skeleton height={15} width={80} count={20} /></td>
//                         <td ><Skeleton height={15} width={90} count={20} /></td>
//                         <td ><Skeleton height={15} width={80} count={20} /></td>
//                         <td ><Skeleton height={15} width={60} count={20} /></td>
//                         <td ><Skeleton height={15} width={60} count={20} /></td>
//                         <td ><Skeleton height={15} width={80} count={20} /></td>
//                         <td ><Skeleton height={15} width={80} count={20} /></td>
//                     </tr>) : props?.data?.transactions?.map((item, index) => {
//                         console.log("item------------->",item)

//                         return (<tr>
//                             <td>{item?.token?.name}</td>
//                             <td>{maskAddress(item.token.address)}
//                                 <OverlayTrigger
//                                     placement="top"
//                                     overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestTransactions${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
//                                 >
//                                     <i class="far fa-copy" onClick={() => copyTokenAddress(item.token.address, `latestTransactions${index}`)}
//                                     ></i>
//                                 </OverlayTrigger></td>
//                             <td>{maskAddress(item.signature)}
//                                 <OverlayTrigger
//                                     placement="top"
//                                     overlay={<Tooltip id={`tooltip-${index}`}>{copied[`signature${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
//                                 >
//                                     <i class="far fa-copy" onClick={() => copyTokenAddress(item.signature, `signature${index}`)}
//                                     ></i>
//                                 </OverlayTrigger></td>
//                             <td>{(item.status == "Manual Sell" || item.status == "Sell") ? item.sol_given : item?.token_amount} {item.token.symbol}</td>
//                             <td>{(item.status == "Manual Sell" || item.status == "Sell") ? item?.token_amount : item.sol_given} SOL</td>
//                             <td>{convertUtcToLocal(item.action_start_at)}</td>
//                             <td>{convertUtcToLocal(item.token.updatedAt)}</td>
//                         </tr>)
//                     })}
//                 </tbody>
//             </Table>

//         </div>
//     </div>

//     )

// }
// export default TransactionLog;




import { useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";

const TransactionLog = ({ maskAddress, copyTokenAddress, copied, checkLogStatus, convertUtcToLocal }) => {
    const [transactionList, setTransactionList] = useState([])
    const [transactionStatus, setTransactionStatus]=useState(true)
    const transactionlogRef = useRef(null);
    const [nextPage, setNextPage] = useState(0);
    useEffect(() => {
        getTransaction(nextPage, "TOP")
    }, [checkLogStatus])

    const getTransaction = async (page, callType) => {
        try {
            const response = await botApis.getTransactionLogs(page);
            if (response.status === 200) {
                let responseData = response.data.data;

                if (callType == "BOTTOM") {
                    let latestTokensTemp = [...transactionList?.transactions];
                    latestTokensTemp.push(...responseData?.transactions);
                    responseData.transactions = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setTransactionList(responseData)
                setTransactionStatus(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    const onScrollTransactionLogs = async () => {
        if (transactionlogRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = transactionlogRef.current;
            // console.log("scrollTop + clientHeight === scrollHeight", scrollTop + clientHeight, scrollHeight);
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(transactionList.totalPages);
                if (page < totalPages) {
                    await getTransaction(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };

    const tokenLogsObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsCallObserverTop(false);
            isCallObserverTopRef.current = false;
        }, 5000);
        return () => clearInterval(interval);
    }, [checkLogStatus]);

    useEffect(() => {
        tokenLogsObserverTop.current = new IntersectionObserver(handleTokenLogsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (transactionList?.transactions?.length > 0) {
            tokenLogsObserverTop.current.observe(document.querySelector('.latest-tokens-logs-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, transactionList, transactionList?.transactions, checkLogStatus]);

    const handleTokenLogsObserverTop = async (entities) => {
        const target = entities[0];
        // console.log("test------->", target.isIntersecting, !isCallObserverTopRef.current, checkLogStatus);
        if (target.isIntersecting && !isCallObserverTopRef.current && checkLogStatus) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getTransaction(0, "TOP");
        }
    };

    return (<>
        <div className="top-boxes selling-top-box pb-0">
            <Form>
                <div className="selling-top-right">
                    <h5>Transaction log</h5>
                </div>
                <Row>
                    <div className="transaction-logs transaction-body token-mis-area transaction-section-bottom"
                        ref={transactionlogRef} onScroll={onScrollTransactionLogs}
                    >
                        <Table >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>signature</th>
                                    <th>Token Amount</th>
                                    <th>Amount </th>
                                    <th>Action Start</th>
                                    <th>Action End</th>
                                </tr>
                            </thead>
                            <tbody>
                                <div className="latest-tokens-logs-top"></div>
                                {
                                // (transactionList.transactions?.length <= 0)
                                transactionStatus ==true
                                 ? (<tr>
                                    <td ><Skeleton height={15} width={80} count={20} /></td>
                                    <td ><Skeleton height={15} width={90} count={20} /></td>
                                    <td ><Skeleton height={15} width={80} count={20} /></td>
                                    <td ><Skeleton height={15} width={60} count={20} /></td>
                                    <td ><Skeleton height={15} width={60} count={20} /></td>
                                    <td ><Skeleton height={15} width={80} count={20} /></td>
                                    <td ><Skeleton height={15} width={80} count={20} /></td>
                                </tr>) :
                                transactionList.transactions?.length >0 ?
                                 transactionList?.transactions?.map((item, index) => {

                                    return (
                                        <>
                                            {index == 1 && <div className="latest-tokens-logs-top"></div>}
                                            <tr>
                                                <td>{item?.token?.name}</td>
                                                <td>{maskAddress(item.token.address)}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestTransactions${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                    >
                                                        <i class="far fa-copy" onClick={() => copyTokenAddress(item.token.address, `latestTransactions${index}`)}
                                                        ></i>
                                                    </OverlayTrigger></td>
                                                <td>{maskAddress(item.signature)}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id={`tooltip-${index}`}>{copied[`signature${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                    >
                                                        <i class="far fa-copy" onClick={() => copyTokenAddress(item.signature, `signature${index}`)}
                                                        ></i>
                                                    </OverlayTrigger></td>
                                                <td>{(item.status == "Manual Sell" || item.status == "Sell") ? item.sol_given : item?.token_amount} {item.token.symbol}</td>
                                                <td>{(item.status == "Manual Sell" || item.status == "Sell") ? item?.token_amount : item.sol_given} SOL</td>
                                                <td>{convertUtcToLocal(item.action_start_at)}</td>
                                                <td>{convertUtcToLocal(item.token.updatedAt)}</td>
                                            </tr>
                                        </>
                                    )
                                }):
                                <>
                                <td colSpan={6} className="no-transaction-area">No Transaction Log available.</td>
                                </>}
                            </tbody>
                        </Table>


                    </div>
                </Row>
            </Form>
        </div>
    </>)
}
export default TransactionLog;