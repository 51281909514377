import { useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";

const AddressLog = ({ maskAddress, copyTokenAddress, copied, checkLogStatus }) => {
    const [addressLogList, setAddressLogList] = useState([])
    const [addressStatus, setAddressStatus]=useState(true)
    const addressLogsRef = useRef(null);
    const [nextPage, setNextPage] = useState(0);
    useEffect(() => {
        getAddressLogs(nextPage, "TOP")
    }, [checkLogStatus])

    const getAddressLogs = async (page, callType) => {
        try {
            const response = await botApis.getWalletAddressLog(page);
            if (response.status === 200) {
                let responseData = response.data.data;

                if (callType == "BOTTOM") {
                    let latestTokensTemp = [...addressLogList?.logs];
                    latestTokensTemp.push(...responseData?.logs);
                    responseData.logs = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setAddressLogList(responseData)
                setAddressStatus(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    const onScrollAddressLogs = async () => {
        if (addressLogsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = addressLogsRef.current;
            // console.log("scrollTop + clientHeight === scrollHeight", scrollTop + clientHeight, scrollHeight);
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(addressLogList.totalPages);
                if (page < totalPages) {
                    await getAddressLogs(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };

    const tokenLogsObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsCallObserverTop(false);
            isCallObserverTopRef.current = false;
        }, 5000);
        return () => clearInterval(interval);
    }, [checkLogStatus]);

    useEffect(() => {
        tokenLogsObserverTop.current = new IntersectionObserver(handleTokenLogsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (addressLogList?.logs?.length > 0) {
            tokenLogsObserverTop.current.observe(document.querySelector('.latest-tokens-logs-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, addressLogList, addressLogList?.logs,checkLogStatus]);

    const handleTokenLogsObserverTop = async (entities) => {
        const target = entities[0];
        // console.log("test------->",target.isIntersecting , !isCallObserverTopRef.current , checkLogStatus);
        if (target.isIntersecting && !isCallObserverTopRef.current && checkLogStatus) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getAddressLogs(0, "TOP");
        }
    };

    return (<>
        <div className="top-boxes selling-top-box pb-0">
            <Form>
                <div className="selling-top-right">
                    <h5>copy wallet address log</h5>
                </div>
                <Row>
                    <div className="transaction-logs transaction-body token-mis-area token-address"
                        ref={addressLogsRef} onScroll={onScrollAddressLogs}
                    >

                        <Table>
                            <thead>
                                <tr>
                                    <th>Token Address</th>
                                    <th>signature</th>
                                    <th>signature_type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                addressStatus ==true
                                 ? (<tr>
                                    <td className="token-front "><Skeleton height={15} width={250} count={20} /></td>
                                    <td className="token-front score-board-right"><Skeleton height={15} width={50} count={20} /></td>
                                </tr>) :
                                   addressLogList?.logs?.length >0 ?
                                    addressLogList?.logs?.map((message, index) => {
                                        const createdAtTimestamp = Date.parse(message.createdAt);
                                        const currentTimestamp = new Date().getTime();
                                        const timeDifference = currentTimestamp - createdAtTimestamp;
                                        const highlightClass = timeDifference < 60000 ? "latest_token" : "";

                                        return (
                                            <>
                                                {index == 1 && <div className="latest-tokens-logs-top"></div>}
                                                <tr className={highlightClass}>
                                                    <td className="token-front">{maskAddress(message.token_address
                                                        , 5)}
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestToken${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                        >
                                                            <i class="far fa-copy" onClick={() => copyTokenAddress(message.token_address, `latestToken${index}`)}
                                                            ></i>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>{maskAddress(message.signature, 5)}
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id={`tooltip-${index}`}>{copied[`signature${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                        >
                                                            <i class="far fa-copy" onClick={() => copyTokenAddress(message.signature, `signature${index}`)}
                                                            ></i>
                                                        </OverlayTrigger></td>
                                                    <td>{message?.signature_type}</td>

                                                </tr>
                                            </>
                                        )
                                    })
                                :
                                <>
                                <td colSpan={8} className="no-transaction-area">No copy token address found.</td>
                                </>}
                            </tbody>
                        </Table>
                    </div>
                </Row>
            </Form>
        </div>
    </>)
}
export default AddressLog;