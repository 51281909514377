import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { botApis } from "../services/botApis";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [error, setError] = useState({ email: "", password: "", both: "" });
    const navigate = useNavigate();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errorTemp = { ...error }, errorStatus = false;
        if (!formData.email) {
            errorTemp.email = "email is required";
            errorStatus = true;
        }
        if (!formData.password) {
            errorTemp.password = "Password is required";
            errorStatus = true;
        }
        if (errorStatus) {
            setError(errorTemp);
            return;
        }
        // Make the HTTP POST request to the login API endpoint
        const params={
            "email": formData.email,
            "password": formData.password
        }
        try {
            const response = await botApis.login(params);
            if (response.status === 200) {
                localStorage.setItem("access_token", response.data.data.token);
                navigate("/home");
            } else {
                setError({ ...error, both: "Invalid email or password" });
                return;
            }
        } catch (error) {
            console.error("Error logging in:", error?.response?.data?.message);
            setError({ ...error, both: error?.response?.data?.message });
        }
    };

    return (
        <>
            <section className="login-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={6} lg={7} md={8}>
                            <div className="login-top">
                                <h1>CopyTradingBot</h1>
                            </div>
                            <div className="login-inner-area">
                                <h2>Login</h2>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            maxLength={20}
                                        />
                                        {error.email && !formData.email && <p className="error">{error.email}</p>}
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Enter Password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            maxLength={20}
                                        />
                                        {error.password && !formData.password && <p className="error">{error.password}</p>}
                                    </Form.Group>

                                    {formData.email && formData.password && error.both && <p className="error">{error.both}</p>}
                                    <Button type="submit" variant="unset">Login</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Login;
